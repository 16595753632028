import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}service-quote`;

/**
 * @description Sends request quote emails
 * @param {Object} data
 * @author Fguzman
*/
export const sendQuote = (data) => {
    return axios.post(
        API_URL,
        { ...data }
    )
};