import { lazy } from 'react';

const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */'../pages/Home'));
const Detail = lazy(() => import(/* webpackChunkName: "DetailPage" */'../pages/Detail'));
const FinanciamientoPage = lazy(() => import(/* webpackChunkName: "FinancingPage" */'../pages/Financiamiento'));
const DetailCarModelPage = lazy(() => import(/* webpackChunkName: "CarDetailPage" */'../pages/DetailCar'));
const AboutPage = lazy(() => import(/* webpackChunkName: "AboutPage" */'../pages/About'));
const PreOwnedPage = lazy(() => import(/* webpackChunkName: "PreownedPage" */'../pages/PreOwned'));
const RefraccionesPage = lazy(() => import(/* webpackChunkName: "RefraccionesPage" */'../pages/Refracciones'));
const AgendarPage = lazy(() => import(/* webpackChunkName: "SchedulePage" */'../pages/Agendar'));
const TermsPage = lazy(() => import(/* webpackChunkName: "SchedulePage" */'../pages/Terms'));
const ViewImage = lazy(() => import(/* webpackChunkName: "ViewImage" */'../pages/ViewImage'));
const CotizarPage = lazy(() => import(/* webpackChunkName: "SchedulePage" */'../pages/Cotizar'));

const routes = [
    {
        path: '/detalle',
        component: Detail,
        exact: true
    },
    {
        path: '/financiamiento',
        component: FinanciamientoPage,
        exact: true
    },
    {
        path: '/conocenos',
        component: AboutPage,
        exact: true
    },
    {
        path: '/seminuevos',
        component: PreOwnedPage,
        exact: true
    },
    {
        path: '/refacciones',
        component: RefraccionesPage,
        exact: true
    },
    {
        path: '/agendar',
        component: AgendarPage,
        exact: true
    },
    {
        path: '/cotizar',
        component: CotizarPage,
        exact: true
    },
    {
        path: '/terminos-y-condiciones',
        component: TermsPage,
        exact: true
    },
    {
        path: '/3D/:slug',
        component: ViewImage,
    },
    {
        path: '/:slug',
        component: DetailCarModelPage
    },
    {
        path: '/',
        component: HomePage,
        exact: true
    },
]

export default routes;