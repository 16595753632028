import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";

import {
  LazyLoader
} from './components';
import { MainRoutes } from './routes';
import { ReactComponent as WhatsAppIcon } from './assets/icons/whatsapp-70.svg';

import './App.css';

const Navbar = lazy(() => import('./common/Navbar'));
const Footer = lazy(() => import('./common/Footer'));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.homeSections.getHomeSections();
    // eslint-disable-next-line
  }, []);

  const [whatsappMenu, setWhatsAppMenu] = useState(false);

  const showWhatsappMenu = () => {
    setWhatsAppMenu(!whatsappMenu);
  };

  return (
    <div className="relative w-full App">
      <Suspense fallback={<LazyLoader />}>
        <Navbar />
        <Switch>
            {
              MainRoutes.map((routeParams, index) => (
                <Route key={`main-routes-${index}`} {...routeParams} />
              ))
            }
          </Switch>
        <Footer />
        <div className="fixed bottom-0 right-0 mb-6 mr-6 whatsap-items">
              {whatsappMenu && 
              <div className="options-whatsapp-box mr-20">
              <div className="option-whatsap">
                <a
                href="https://api.whatsapp.com/send?phone=3316032391&text=¡Hola!%20Me%20interesa%20contactar%20con%20el%20área%20de%20refacciones."
                target="_blank" >
                  Refacciones
                </a>
              </div>
              <div className="option-whatsap">
                <a
                href="https://api.whatsapp.com/send?phone=3322577807&text=Me%20interesa%20contactar%20con%20un%20asesor%20de%20servicio."
                target="_blank" >
                  Servicio
                </a>
              </div>
              <div className="option-whatsap">
                <a
                href="https://api.whatsapp.com/send?phone=523313981064&text=Vi%20esto%20en%20Google%20y%20me%20interesa%20informaci%C3%B3n%20sobre%20un%20Acura.%20%F0%9F%9A%99"
                target="_blank" >
                  Cotiza tu auto nuevo
                </a>
              </div>
            </div>}
            <WhatsAppIcon onClick={showWhatsappMenu} />
        </div>
      </Suspense>
    </div>
  );
}

export default App;
